import React from 'react';
import PropTypes from 'prop-types';

const IconLogo = props => {
  const { className, format, ...rest } = props;

  if (format === 'desktop') {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="144.698" height="40" viewBox="0 0 184.698 67.699">
          <g id="Group_19" transform="translate(0.006)">
            <path id="Path_16" d="M101.53,46.942A16.37,16.37,0,1,1,73.565,35.365a16.616,16.616,0,0,1,23.227,0,15.746,15.746,0,0,1,4.744,11.577m-8.261,0a8.139,8.139,0,0,0-8.076-8.05,7.894,7.894,0,0,0-5.75,2.346,7.7,7.7,0,0,0-2.387,5.7,8.11,8.11,0,1,0,16.219.128v-.128" transform="translate(-33.475 -14.905)" fill="#ffce00"/>
            <path id="Path_17" d="M171.987,48.41h-8.261V32.966a8.161,8.161,0,0,0-8.112-8.061,7.936,7.936,0,0,0-5.776,2.351,7.7,7.7,0,0,0-2.393,5.709V48.41H139.18V0h8.266V18.729a16.647,16.647,0,0,1,8.138-2.079,15.9,15.9,0,0,1,11.629,4.734,15.663,15.663,0,0,1,4.775,11.577V48.415Z" transform="translate(-67.726)" fill="#ffce00"/>
            <path id="Path_18" d="M240.9,46.939a16.371,16.371,0,0,1-32.74.262v-.262a15.671,15.671,0,0,1,4.775-11.577,16.606,16.606,0,0,1,23.227,0A15.746,15.746,0,0,1,240.9,46.939m-8.261,0a8.139,8.139,0,0,0-8.076-8.05,7.894,7.894,0,0,0-5.75,2.346,7.7,7.7,0,0,0-2.387,5.7,8.11,8.11,0,1,0,16.219.128v-.128" transform="translate(-101.288 -14.902)" fill="#ffce00"/>
            <path id="Path_19" d="M299.826,53.723a8.234,8.234,0,0,1-1.961,5.339q-3.92,4.782-11.141,4.6a19.891,19.891,0,0,1-6.274-1.284,17.642,17.642,0,0,1-5.54-3.06l4.59-5.935a10.146,10.146,0,0,0,7.039,3.122h.185a6.674,6.674,0,0,0,2.752-.549,2.286,2.286,0,0,0,1.592-2.079v-.246a2.5,2.5,0,0,0-1.9-2.018c-.452-.082-1.407-.267-2.875-.549a24.47,24.47,0,0,1-4.651-1.345,8.463,8.463,0,0,1-5.571-8.384q0-5.753,5.694-8.63a12.34,12.34,0,0,1,5.447-1.345,18.922,18.922,0,0,1,6.366,1.042,11.019,11.019,0,0,1,5.386,3.368l-5.509,4.96a6.412,6.412,0,0,0-4.652-2.2q-3.92,0-3.917,2.577v.123c0,.816,1.063,1.515,3.183,2.084q.246.062,4.041.8,7.709,1.471,7.711,9.447v.18Z" transform="translate(-133.771 -15.259)" fill="#ffce00"/>
            <path id="Path_20" d="M344.191,48.41a15.88,15.88,0,0,1-11.629-4.734,15.682,15.682,0,0,1-4.775-11.583V0h8.261V16.645h8.138v7.039h-8.138v8.384a7.742,7.742,0,0,0,2.387,5.725,7.883,7.883,0,0,0,5.75,2.357V48.41Z" transform="translate(-159.498)" fill="#ffce00"/>
            <path id="Path_21" d="M32.737,46.943a15.835,15.835,0,0,1-4.744,11.608,15.735,15.735,0,0,1-11.6,4.764,15.869,15.869,0,0,1-11.66-4.734,16.535,16.535,0,0,1,.031-23.211A15.88,15.88,0,0,1,16.4,30.637a15.811,15.811,0,0,1,11.6,4.734,15.746,15.746,0,0,1,4.744,11.577m-8.261,0a7.715,7.715,0,0,0-2.387-5.668A7.791,7.791,0,0,0,16.4,38.9a7.894,7.894,0,0,0-5.75,2.346,7.7,7.7,0,0,0-2.387,5.7,7.793,7.793,0,0,0,2.387,5.73,7.846,7.846,0,0,0,5.75,2.377,8.081,8.081,0,0,0,8.081-8.107" transform="translate(0 -14.906)" fill="#ffce00"/>
            <path id="Path_22" d="M24.815,100.48a8.061,8.061,0,0,1-16.116,0H.52a16.24,16.24,0,0,0,32.479,0H24.82Z" transform="translate(-0.256 -48.892)" fill="#ffce00"/>
            <path id="Path_23" d="M232.715,115.54a8.061,8.061,0,0,0-16.116,0H208.42a16.24,16.24,0,0,1,32.479,0H232.72Z" transform="translate(-101.418 -48.381)" fill="#ffce00"/>
          </g>
    </svg>
    );
  }

  return (
    <svg
    className={className}
    {...rest}
    width="28"
    height="28"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 256 256"
    >
      <circle className="cls-1" cx="128" cy="128" r="128" fill="#ffce00"/>
      <g>
        <path className="cls-2" fill="#1a1a1a" d="M215.33,103.05c0,11.31-3.9,20.92-11.83,28.85s-17.54,11.83-28.85,11.83-21.18-3.9-28.98-11.83c-7.93-7.8-11.83-17.54-11.83-28.98s3.9-20.92,11.83-28.85c7.93-7.8,17.54-11.83,28.98-11.83s20.92,3.9,28.85,11.83c7.93,7.93,11.83,17.54,11.83,28.98m-20.53,0c0-5.46-1.95-10.14-5.98-14.16-3.9-3.9-8.71-5.98-14.16-5.98s-10.4,1.95-14.29,5.85-5.98,8.58-5.98,14.16,1.95,10.27,5.98,14.29,8.71,5.98,14.29,5.98,10.27-1.95,14.29-5.98c3.9-3.9,5.85-8.71,5.85-14.16" />
        <path className="cls-2" fill="#1a1a1a" d="M122.15,103.05c0,11.31-3.9,20.92-11.83,28.85s-17.54,11.83-28.85,11.83-21.18-3.9-28.98-11.83c-7.93-7.8-11.83-17.54-11.83-28.98s3.9-20.92,11.83-28.85c7.93-7.8,17.54-11.83,28.98-11.83s20.92,3.9,28.85,11.83c7.8,7.93,11.83,17.54,11.83,28.98m-20.53,0c0-5.46-1.95-10.14-5.98-14.16s-8.71-5.98-14.16-5.98-10.4,1.95-14.29,5.85-5.98,8.58-5.98,14.16,1.95,10.27,5.98,14.29c3.9,3.9,8.71,5.98,14.29,5.98s10.27-1.95,14.29-5.98c3.77-3.9,5.85-8.71,5.85-14.16" />
        <path className="cls-2" fill="#1a1a1a" d="M101.49,153.47c-.13,10.92-9.1,19.75-20.01,19.75s-19.88-8.84-20.01-19.75h-20.53c.13,22.09,18.19,40.15,40.41,40.15s40.28-17.93,40.41-40.15h-20.27Z" />
        <path className="cls-2" fill="#1a1a1a" d="M194.66,191.16c-.13-10.92-9.1-19.75-20.01-19.75s-19.88,8.84-20.01,19.75h-20.4c.13-22.09,18.19-40.15,40.41-40.15s40.28,17.93,40.41,40.15h-20.4Z" />
      </g>
    </svg>
  );
};

const { string } = PropTypes;

IconLogo.defaultProps = {
  className: null,
};

IconLogo.propTypes = {
  className: string,
};

export default IconLogo;
