import React from 'react';
import { arrayOf } from 'prop-types';

import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';

import css from '../BookingBreakdown/BookingBreakdown.module.css';

const { Money } = sdkTypes;

const LineItemAllBasePriceMaybe = props => {
  const { txs, unitType, intl } = props;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;
  const translationKey = isNightly
    ? 'BookingBreakdown.baseUnitNight'
    : isDaily
    ? 'BookingBreakdown.baseUnitDay'
    : 'BookingBreakdown.baseUnitQuantity';

  // Find correct line-item for given unitType prop.
  // It should be one of the following: 'line-item/night, 'line-item/day', 'line-item/units', or 'line-item/time'
  // These are defined in '../../util/types';
  const unitPurchases = txs
    .map(item => item.attributes.lineItems)
    .flat()
    .filter(item => item.code === unitType && !item.reversal)
  ;
  if (!unitPurchases.length) {
    return null;
  }
  const firstUnit = unitPurchases[0];
  const quantity = unitPurchases
    .reduce((acc, currLineItem) => acc + Number(currLineItem.quantity), 0)
    .toString()
  ;
  const linesTotal = unitPurchases
    .reduce((acc, currLineItem) => acc + currLineItem.lineTotal.amount, 0)
  ;
  const unitPrice = formatMoney(intl, firstUnit.unitPrice);
  const total = formatMoney(intl, new Money(linesTotal, firstUnit.unitPrice.currency));

  return quantity && total ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id={translationKey} values={{ unitPrice, quantity }} />
      </span>
      <span className={css.itemValue}>{total}</span>
    </div>
  ) : null;
};

LineItemAllBasePriceMaybe.propTypes = {
  txs: arrayOf(propTypes.transaction).isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemAllBasePriceMaybe;
