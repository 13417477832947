import React from 'react';
import { bool, arrayOf } from 'prop-types';

import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { LINE_ITEM_PROVIDER_COMMISSION, propTypes } from '../../util/types';

import css from '../BookingBreakdown/BookingBreakdown.module.css';

const { Money } = sdkTypes;

// Validate the assumption that the commission exists and the amount
// is zero or negative.
const isValidCommission = commissionLineItem => {
  return commissionLineItem.lineTotal instanceof Money && commissionLineItem.lineTotal.amount <= 0;
};

const LineItemAllProviderCommissionMaybe = props => {
  const { txs, isProvider, intl } = props;

  const providerCommissionLineItems = txs
    .map(item => item.attributes.lineItems)
    .flat()
    .filter(item => item.code === LINE_ITEM_PROVIDER_COMMISSION && !item.reversal)
  ;

  if (!providerCommissionLineItems.length || !isProvider) {
    return null;
  }

  const firstLineItem = providerCommissionLineItems[0];
  // Flex Template for Web is using the default transaction process (https://www.sharetribe.com/docs/background/transaction-process/#sharetribe-flex-default-transaction-process)
  // which containt provider commissions so by default the providerCommissionLineItem should exist.
  // If you are not using provider commisison you might want to remove this whole component from BookingBreakdown.js file.
  const isValidLineItems = providerCommissionLineItems.every(item => isValidCommission(item));
  if (!isValidLineItems) {
    throw new Error('Commission should be present and the value should be zero or negative');
  }

  const commissionAmount = providerCommissionLineItems
    .reduce((acc, currLineItem) => acc + currLineItem.lineTotal.amount, 0)
  ;
  const commission = new Money(commissionAmount, firstLineItem.lineTotal.currency);
  const formattedCommission = formatMoney(intl, commission);

  return (
    <div className={css.lineItem}>
        <span className={css.itemLabel}>
          <FormattedMessage id="BookingBreakdown.commission" />
        </span>
      <span className={css.itemValue}>{formattedCommission}</span>
    </div>
  );
};

LineItemAllProviderCommissionMaybe.propTypes = {
  txs: arrayOf(propTypes.transaction).isRequired,
  isProvider: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemAllProviderCommissionMaybe;
