import React from 'react'
import { func } from 'prop-types';

import { WEEKDAYS } from '../../util/availabilityHelpers';
import { propTypes } from '../../util/types';

import Weekday from './Weekday';

import css from './EditListingAvailabilityPanel.module.css';

const Week = (props) => {
  const {
    availabilityPlan,
    onOpenEditModal,
  } = props;

  return (
    <div className={css.week}>
      {WEEKDAYS.map(w => (
        <Weekday
          dayOfWeek={w}
          key={w}
          availabilityPlan={availabilityPlan}
          openEditModal={onOpenEditModal}
        />
      ))}
    </div>
  );
};

Week.propTypes = {
  availabilityPlan: propTypes.availabilityPlan.isRequired,
  onOpenEditModal: func,
};

export default Week;
