import React from 'react';
import { bool, arrayOf } from 'prop-types';

import { types as sdkTypes } from '../../util/sdkLoader';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { txIsCanceled, txIsDelivered, txIsDeclined } from '../../util/transaction';
import { propTypes } from '../../util/types';

import css from '../BookingBreakdown/BookingBreakdown.module.css';

const { Money } = sdkTypes;

const LineItemAllUnitPrice = props => {
  const { txs, isProvider, intl } = props;
  if (!txs.length) {
    return null;
  }
  const firstTx = txs[0];
  let providerTotalMessageId = 'BookingBreakdown.providerTotalDefault';
  if (txIsDelivered(firstTx)) {
    providerTotalMessageId = 'BookingBreakdown.providerTotalDelivered';
  } else if (txIsDeclined(firstTx)) {
    providerTotalMessageId = 'BookingBreakdown.providerTotalDeclined';
  } else if (txIsCanceled(firstTx)) {
    providerTotalMessageId = 'BookingBreakdown.providerTotalCanceled';
  }

  const totalLabel = isProvider ? (
    <FormattedMessage id={providerTotalMessageId} />
  ) : (
    <FormattedMessage id="BookingBreakdown.total" />
  );

  const totalPrices = isProvider
    ? txs.map(item => item.attributes.payoutTotal)
    : txs.map(item => item.attributes.payinTotal)
  ;
  const firstTotalPrice = totalPrices[0];
  const totalPriceAmount = totalPrices.reduce((acc, currLineItem) => acc + currLineItem.amount, 0);
  const totalPrice = new Money(totalPriceAmount, firstTotalPrice.currency);
  const formattedTotalPrice = formatMoney(intl, totalPrice);

  return (
    <>
      <hr className={css.totalDivider} />
      <div className={css.lineItemTotal}>
        <div className={css.totalLabel}>{totalLabel}</div>
        <div className={css.totalPrice}>{formattedTotalPrice}</div>
      </div>
    </>
  );
};

LineItemAllUnitPrice.propTypes = {
  txs: arrayOf(propTypes.transaction).isRequired,
  isProvider: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemAllUnitPrice;
