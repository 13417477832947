import React, { useState } from 'react';
import { arrayOf, bool, func, object, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { getDefaultTimeZone, timestampToDate } from '../../util/dates';
import { LISTING_STATE_DRAFT, DATE_TYPE_DATETIME, propTypes } from '../../util/types';
import { DEFAULT_AVAILABILITY_PLAN, WEEKDAYS } from '../../util/availabilityHelpers';

import {
  Button,
  IconClose,
  IconEdit,
  IconSpinner,
  InlineTextButton,
  ListingLink,
  Modal,
  TimeRange,
} from '../../components';
import { EditListingAvailabilityPlanForm, EditListingAvailabilityExceptionForm } from '../../forms';

import css from './EditListingAvailabilityPanel.module.css';
import Week from './Week';

// We want to sort exceptions on the client-side, maximum pagination page size is 100,
// so we need to restrict the amount of exceptions to that.
const MAX_EXCEPTIONS_COUNT = 100;

///////////////////////////////////////////////////
// EditListingAvailabilityExceptionPanel - utils //
///////////////////////////////////////////////////

// Create initial entry mapping for form's initial values
const createEntryDayGroups = (entries = {}) =>
  entries.reduce((groupedEntries, entry) => {
    const { startTime, endTime: endHour, dayOfWeek } = entry;
    const dayGroup = groupedEntries[dayOfWeek] || [];
    return {
      ...groupedEntries,
      [dayOfWeek]: [
        ...dayGroup,
        {
          startTime,
          endTime: endHour === '00:00' ? '24:00' : endHour,
        },
      ],
    };
  }, {});

// Create initial values
const createInitialValues = availabilityPlan => {
  const { timezone, entries } = availabilityPlan || {};
  const tz = timezone || getDefaultTimeZone();
  return {
    timezone: tz,
    ...createEntryDayGroups(entries),
  };
};

// Create entries from submit values
const createEntriesFromSubmitValues = values =>
  WEEKDAYS.reduce((allEntries, dayOfWeek) => {
    const dayValues = values[dayOfWeek] || [];
    const dayEntries = dayValues.map(dayValue => {
      const { startTime, endTime } = dayValue;
      // Note: This template doesn't support seats yet.
      return startTime && endTime
        ? {
            dayOfWeek,
            seats: 1,
            startTime,
            endTime: endTime === '24:00' ? '00:00' : endTime,
          }
        : null;
    });

    return allEntries.concat(dayEntries.filter(e => !!e));
  }, []);

// Create availabilityPlan from submit values
const createAvailabilityPlan = values => ({
  availabilityPlan: {
    type: 'availability-plan/time',
    timezone: values.timezone,
    entries: createEntriesFromSubmitValues(values),
  },
});

// Ensure that the AvailabilityExceptions are in sensible order.
//
// Note: if you allow fetching more than 100 exception,
// pagination kicks in and that makes client-side sorting impossible.
const sortExceptionsByStartTime = (a, b) => {
  return a.attributes.start.getTime() - b.attributes.start.getTime();
};

//////////////////////////////////
// EditListingAvailabilityPanel //
//////////////////////////////////
const EditListingAvailabilityPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    availabilityExceptions,
    fetchExceptionsInProgress,
    onAddAvailabilityException,
    onDeleteAvailabilityException,
    disabled,
    ready,
    onSubmit,
    onManageDisableScrolling,
    onNextTab,
    submitButtonText,
    updateInProgress,
    errors,
  } = props;
  // Hooks
  const [isEditPlanModalOpen, setIsEditPlanModalOpen] = useState(false);
  const [isEditExceptionsModalOpen, setIsEditExceptionsModalOpen] = useState(false);
  const [valuesFromLastSubmit, setValuesFromLastSubmit] = useState(null);

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const isNextButtonDisabled = !currentListing.attributes.availabilityPlan;
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const availabilityPlan = currentListing.attributes.availabilityPlan || DEFAULT_AVAILABILITY_PLAN;

  const initialValues = valuesFromLastSubmit
    ? valuesFromLastSubmit
    : createInitialValues(availabilityPlan);

  const handleSubmit = values => {
    setValuesFromLastSubmit(values);

    // Final Form can wait for Promises to return.
    return onSubmit(createAvailabilityPlan(values))
      .then(() => {
        setIsEditPlanModalOpen(false);
      })
      .catch(e => {
        // Don't close modal if there was an error
      });
  };

  const exceptionCount = availabilityExceptions ? availabilityExceptions.length : 0;
  const sortedAvailabilityExceptions = availabilityExceptions.sort(sortExceptionsByStartTime);

  // Save exception click handler
  const saveException = values => {
    const { availability, exceptionStartTime, exceptionEndTime } = values;

    // TODO: add proper seat handling
    const seats = availability === 'available' ? 1 : 0;

    return onAddAvailabilityException({
      listingId: listing.id,
      seats,
      start: timestampToDate(exceptionStartTime),
      end: timestampToDate(exceptionEndTime),
    })
      .then(() => {
        setIsEditExceptionsModalOpen(false);
      })
      .catch(e => {
        // Don't close modal if there was an error
      });
  };

  return (
    <main className={classes}>
      <h1 className={css.title}>
        {isPublished ? (
          <FormattedMessage
            id="EditListingAvailabilityPanel.title"
            values={{
              listingTitle: (
                <ListingLink listing={listing}>
                  <FormattedMessage id="EditListingAvailabilityPanel.listingTitle" />
                </ListingLink>
              ),
            }}
          />
        ) : (
          <FormattedMessage id="EditListingAvailabilityPanel.createListingTitle" />
        )}
      </h1>

      <section className={css.section}>
        <header className={css.sectionHeader}>
          <h2 className={css.sectionTitle}>
            <FormattedMessage id="EditListingAvailabilityPanel.defaultScheduleTitle" />
          </h2>
          <InlineTextButton
            className={css.editPlanButton}
            onClick={() => setIsEditPlanModalOpen(true)}
          >
            <IconEdit className={css.editPlanIcon} />{' '}
            <FormattedMessage id="EditListingAvailabilityPanel.edit" />
          </InlineTextButton>
        </header>
        <Week
          availabilityPlan={availabilityPlan}
          onOpenEditModal={setIsEditPlanModalOpen}
        />
      </section>
      {/*<section className={css.section}>*/}
      {/*  <header className={css.sectionHeader}>*/}
      {/*    <h2 className={css.sectionTitle}>*/}
      {/*      {fetchExceptionsInProgress ? (*/}
      {/*        <FormattedMessage id="EditListingAvailabilityPanel.availabilityExceptionsTitleNoCount" />*/}
      {/*      ) : (*/}
      {/*        <FormattedMessage*/}
      {/*          id="EditListingAvailabilityPanel.availabilityExceptionsTitle"*/}
      {/*          values={{ count: exceptionCount }}*/}
      {/*        />*/}
      {/*      )}*/}
      {/*    </h2>*/}
      {/*  </header>*/}
      {/*  {fetchExceptionsInProgress ? (*/}
      {/*    <div className={css.exceptionsLoading}>*/}
      {/*      <IconSpinner />*/}
      {/*    </div>*/}
      {/*  ) : exceptionCount === 0 ? (*/}
      {/*    <div className={css.noExceptions}>*/}
      {/*      <FormattedMessage id="EditListingAvailabilityPanel.noExceptions" />*/}
      {/*    </div>*/}
      {/*  ) : (*/}
      {/*    <div className={css.exceptions}>*/}
      {/*      {sortedAvailabilityExceptions.map(availabilityException => {*/}
      {/*        const { start, end, seats } = availabilityException.attributes;*/}
      {/*        return (*/}
      {/*          <div key={availabilityException.id.uuid} className={css.exception}>*/}
      {/*            <div className={css.exceptionHeader}>*/}
      {/*              <div className={css.exceptionAvailability}>*/}
      {/*                <div*/}
      {/*                  className={classNames(css.exceptionAvailabilityDot, {*/}
      {/*                    [css.isAvailable]: seats > 0,*/}
      {/*                  })}*/}
      {/*                />*/}
      {/*                <div className={css.exceptionAvailabilityStatus}>*/}
      {/*                  {seats > 0 ? (*/}
      {/*                    <FormattedMessage id="EditListingAvailabilityPanel.exceptionAvailable" />*/}
      {/*                  ) : (*/}
      {/*                    <FormattedMessage id="EditListingAvailabilityPanel.exceptionNotAvailable" />*/}
      {/*                  )}*/}
      {/*                </div>*/}
      {/*              </div>*/}
      {/*              <button*/}
      {/*                className={css.removeExceptionButton}*/}
      {/*                onClick={() =>*/}
      {/*                  onDeleteAvailabilityException({ id: availabilityException.id })*/}
      {/*                }*/}
      {/*              >*/}
      {/*                <IconClose size="normal" className={css.removeIcon} />*/}
      {/*              </button>*/}
      {/*            </div>*/}
      {/*            <TimeRange*/}
      {/*              className={css.timeRange}*/}
      {/*              startDate={start}*/}
      {/*              endDate={end}*/}
      {/*              dateType={DATE_TYPE_DATETIME}*/}
      {/*              timeZone={availabilityPlan.timezone}*/}
      {/*            />*/}
      {/*          </div>*/}
      {/*        );*/}
      {/*      })}*/}
      {/*    </div>*/}
      {/*  )}*/}
      {/*  {exceptionCount <= MAX_EXCEPTIONS_COUNT ? (*/}
      {/*    <InlineTextButton*/}
      {/*      className={css.addExceptionButton}*/}
      {/*      onClick={() => setIsEditExceptionsModalOpen(true)}*/}
      {/*      disabled={disabled}*/}
      {/*      ready={ready}*/}
      {/*    >*/}
      {/*      <FormattedMessage id="EditListingAvailabilityPanel.addException" />*/}
      {/*    </InlineTextButton>*/}
      {/*  ) : null}*/}
      {/*</section>*/}

      {errors.showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingAvailabilityPanel.showListingFailed" />
        </p>
      ) : null}

      {!isPublished ? (
        <Button
          className={css.goToNextTabButton}
          onClick={onNextTab}
          disabled={isNextButtonDisabled}
        >
          {submitButtonText}
        </Button>
      ) : null}
      {onManageDisableScrolling ? (
        <Modal
          id="EditAvailabilityPlan"
          isOpen={isEditPlanModalOpen}
          onClose={() => setIsEditPlanModalOpen(false)}
          onManageDisableScrolling={onManageDisableScrolling}
          containerClassName={css.modalContainer}
          usePortal
        >
          <EditListingAvailabilityPlanForm
            formId="EditListingAvailabilityPlanForm"
            listingTitle={currentListing.attributes.title}
            availabilityPlan={availabilityPlan}
            weekdays={WEEKDAYS}
            onSubmit={handleSubmit}
            initialValues={initialValues}
            inProgress={updateInProgress}
            fetchErrors={errors}
          />
        </Modal>
      ) : null}
      {onManageDisableScrolling ? (
        <Modal
          id="EditAvailabilityExceptions"
          isOpen={isEditExceptionsModalOpen}
          onClose={() => setIsEditExceptionsModalOpen(false)}
          onManageDisableScrolling={onManageDisableScrolling}
          containerClassName={css.modalContainer}
          usePortal
        >
          <EditListingAvailabilityExceptionForm
            formId="EditListingAvailabilityExceptionForm"
            onSubmit={saveException}
            timeZone={availabilityPlan.timezone}
            availabilityExceptions={sortedAvailabilityExceptions}
            updateInProgress={updateInProgress}
            fetchErrors={errors}
          />
        </Modal>
      ) : null}
    </main>
  );
};

EditListingAvailabilityPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
  availabilityExceptions: [],
};

EditListingAvailabilityPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  availabilityExceptions: arrayOf(propTypes.availabilityException),
  fetchExceptionsInProgress: bool.isRequired,
  onAddAvailabilityException: func.isRequired,
  onDeleteAvailabilityException: func.isRequired,
  onSubmit: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onNextTab: func.isRequired,
  submitButtonText: string.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingAvailabilityPanel;
