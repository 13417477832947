import { getDefaultTimeZone } from './dates';

export const WEEKDAYS = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

export const DEFAULT_AVAILABILITY_PLAN = {
  type: 'availability-plan/time',
  timezone: getDefaultTimeZone(),
  entries: [
    // { dayOfWeek: 'mon', startTime: '09:00', endTime: '17:00', seats: 1 },
    // { dayOfWeek: 'tue', startTime: '09:00', endTime: '17:00', seats: 1 },
    // { dayOfWeek: 'wed', startTime: '09:00', endTime: '17:00', seats: 1 },
    // { dayOfWeek: 'thu', startTime: '09:00', endTime: '17:00', seats: 1 },
    // { dayOfWeek: 'fri', startTime: '09:00', endTime: '17:00', seats: 1 },
    // { dayOfWeek: 'sat', startTime: '09:00', endTime: '17:00', seats: 1 },
    // { dayOfWeek: 'sun', startTime: '09:00', endTime: '17:00', seats: 1 },
  ],
};
