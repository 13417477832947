import React from 'react';
import { array, string } from 'prop-types';

import config from '../../config';
import { DATE_TYPE_DATETIME } from '../../util/types';
import { ensureBooking } from '../../util/data';
import { intlShape } from '../../util/reactIntl';
import {
  isProvider,
  isCustomer,
} from '../../util/userHelpers';

import LineItemBookingPeriod from '../BookingBreakdown/LineItemBookingPeriod';
import LineItemUnitPriceMaybe from '../BookingBreakdown/LineItemUnitPriceMaybe';
import LineItemAllBasePriceMaybe from './LineItemAllBasePriceMaybe';
import LineItemAllTotalPrice from './LineItemAllTotalPrice';
import LineItemAllCustomerCommissionMaybe from './LineItemAllCustomerCommissionMaybe';
import LineItemAllCustomerCommissionRefundMaybe from './LineItemAllCustomerCommissionRefundMaybe';
import LineItemAllProviderCommissionMaybe from './LineItemAllProviderCommissionMaybe';
import LineItemAllProviderCommissionRefundMaybe from './LineItemAllProviderCommissionRefundMaybe';
import LineItemAllRefundMaybe from './LineItemAllRefundMaybe';

const AllBookingBreakdown = (props) => {
  const {
    className,
    txs,
    timeZone,
    intl,
    userRole,
  } = props;

  const unitType = config.bookingUnitType;
  const firstTx = txs.length ? txs[0] : null;

  return (
    <div className={className}>
      {txs.map((tx, index) => {
        const txBooking = ensureBooking(tx.booking);
        const isFirstItem = index === 0;
        return (
          <LineItemBookingPeriod
            key={`${tx.id.uuid}_${index}`}
            booking={txBooking}
            unitType={unitType}
            dateType={DATE_TYPE_DATETIME}
            timeZone={timeZone}
            showLabels={isFirstItem}
          />
        );
      })}
      {firstTx && (
        <LineItemUnitPriceMaybe
          transaction={firstTx}
          unitType={unitType}
          intl={intl}
        />
      )}
      <LineItemAllBasePriceMaybe
        txs={txs}
        unitType={unitType}
        intl={intl}
      />
      <LineItemAllRefundMaybe
        txs={txs}
        intl={intl}
      />
      <LineItemAllCustomerCommissionMaybe
        txs={txs}
        isCustomer={isCustomer(userRole)}
        intl={intl}
      />
      <LineItemAllCustomerCommissionRefundMaybe
        txs={txs}
        intl={intl}
        isCustomer={isCustomer(userRole)}
      />
      <LineItemAllProviderCommissionMaybe
        txs={txs}
        isProvider={isProvider(userRole)}
        intl={intl}
      />
      <LineItemAllProviderCommissionRefundMaybe
        txs={txs}
        intl={intl}
        isProvider={isProvider(userRole)}
      />
      <LineItemAllTotalPrice
        txs={txs}
        isProvider={isProvider(userRole)}
        intl={intl}
     />
    </div>
  )
};

AllBookingBreakdown.propTypes = {
  className: string,
  txs: array,
  timeZone: string,
  intl: intlShape.isRequired,
  userRole: string.isRequired,
};

export default AllBookingBreakdown;
