import React from 'react';
import { bool, arrayOf } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { LINE_ITEM_CUSTOMER_COMMISSION, propTypes } from '../../util/types';

import css from '../BookingBreakdown/BookingBreakdown.module.css';

const { Money } = sdkTypes;

// Validate the assumption that the commission exists and the amount
// is zero or positive.
const isValidCommission = commissionLineItem => {
  return (
    commissionLineItem &&
    commissionLineItem.lineTotal instanceof Money &&
    commissionLineItem.lineTotal.amount >= 0
  );
};

const LineItemCustomerCommissionMaybe = props => {
  const { txs, isCustomer, intl } = props;

  const customerCommissionLineItems = txs
    .map(item => item.attributes.lineItems)
    .flat()
    .filter(item => item.code === LINE_ITEM_CUSTOMER_COMMISSION && !item.reversal)
  ;

  if (!customerCommissionLineItems.length || !isCustomer) {
    return null;
  }
  const firstLineItem = customerCommissionLineItems[0];
  // If commission is passed it will be shown as a fee already reduces from the total price
  const isValidLineItems = customerCommissionLineItems.every(item => isValidCommission(item));
  if (!isValidLineItems) {
    throw new Error('Commission should be present and the value should be zero or positive');
  }

  const commissionAmount = customerCommissionLineItems
    .reduce((acc, currLineItem) => acc + currLineItem.lineTotal.amount, 0)
  ;
  const commission = new Money(commissionAmount, firstLineItem.lineTotal.currency);
  const formattedCommission = formatMoney(intl, commission);

  return (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="BookingBreakdown.commission" />
      </span>
      <span className={css.itemValue}>{formattedCommission}</span>
    </div>
  );
};

LineItemCustomerCommissionMaybe.propTypes = {
  txs: arrayOf(propTypes.transaction).isRequired,
  isCustomer: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemCustomerCommissionMaybe;
