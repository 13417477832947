import React from 'react';
import { arrayOf, bool } from 'prop-types';

import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { propTypes, LINE_ITEM_CUSTOMER_COMMISSION } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';

import css from '../BookingBreakdown/BookingBreakdown.module.css';

const { Money } = sdkTypes;

const LineItemAllCustomerCommissionRefundMaybe = props => {
  const { txs, isCustomer, intl } = props;

  const refunds = txs.map(item => item.attributes.lineItems)
    .flat()
    .filter(item => item.code === LINE_ITEM_CUSTOMER_COMMISSION && item.reversal)
  ;

  if (!refunds.length || !isCustomer) {
    return null;
  }
  const firstRefund = refunds[0];
  const refundsAmount = refunds
    .reduce((acc, currLineItem) => acc + currLineItem.lineTotal.amount, 0)
  ;
  const refund = new Money(refundsAmount, firstRefund.lineTotal.currency);
  const formattedRefund = formatMoney(intl, refund);

  return (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="BookingBreakdown.refundCustomerFee" />
      </span>
      <span className={css.itemValue}>{formattedRefund}</span>
    </div>
  );
};

LineItemAllCustomerCommissionRefundMaybe.propTypes = {
  txs: arrayOf(propTypes.transaction).isRequired,
  intl: intlShape.isRequired,
  isCustomer: bool,
};

export default LineItemAllCustomerCommissionRefundMaybe;
