import React from 'react';
import classNames from 'classnames';
import { string, bool, arrayOf } from 'prop-types';

import { propTypes } from '../../util/types';
import { ensureListing } from '../../util/data';
import { intlShape } from '../../util/reactIntl';

import AllBookingBreakdown from '../AllBookingBreakdown/AllBookingBreakdown';

import css from './TransactionPanel.module.css';

// Functional component as a helper to build BookingBreakdown
const BreakdownMaybe = props => {
  const {
    className,
    rootClassName,
    breakdownClassName,
    transaction,
    transactionRole,
    groupTxs,
    groupTxsInProgress,
    intl,
  } = props;

  const loaded = transaction &&
    transaction.id &&
    transaction.booking &&
    transaction.booking.id &&
    !groupTxsInProgress
  ;

  if (!loaded) {
    return null;
  }

  const listingAttributes = ensureListing(transaction.listing).attributes;
  const timeZone =
    loaded && listingAttributes.availabilityPlan
      ? listingAttributes.availabilityPlan.timezone
      : 'Etc/UTC';

  const classes = classNames(rootClassName || css.breakdownMaybe, className);
  const breakdownClasses = classNames(classes, breakdownClassName || css.breakdown);

  return (
    <AllBookingBreakdown
      className={breakdownClasses}
      txs={groupTxs}
      timeZone={timeZone}
      intl={intl}
      userRole={transactionRole}
    />
  );
};

BreakdownMaybe.propTypes = {
  className: string,
  rootClassName: string,
  breakdownClassName: string,
  transaction: propTypes.transaction.isRequired,
  transactionRole: string.isRequired,
  groupTxs: arrayOf(propTypes.transaction).isRequired,
  groupTxsInProgress: bool,
  intl: intlShape.isRequired,
};

export default BreakdownMaybe;
