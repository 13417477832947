import React from 'react';
import { BookingTimeForm } from '../../forms';
import { array, bool, func, node, object, oneOfType, shape, string } from 'prop-types';
import { propTypes } from '../../util/types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import EstimatedBreakdownMaybe from '../../forms/BookingTimeForm/EstimatedBreakdownMaybe';
import css from './BookingPanel.module.css';
import bookingTimeFormCss from '../../forms/BookingTimeForm/BookingTimeForm.module.css';
import { IconSpinner, PrimaryButton } from '../index';
import classNames from 'classnames';

const dateFormattingOptions = { month: 'short', day: 'numeric', weekday: 'short' };
const TODAY = new Date();

const BookingForm = (props) => {
  const {
    hasBookingLineItems,
    bookingDates,
    bookingLineItems,
    submitButtonWrapperClassName,
    unitType,
    price,
    listingId,
    isOwnListing,
    monthlyTimeSlots,
    onFetchTimeSlots,
    intl,
    timeZone,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    onSubmit,
    onFetchTransactionLineItems,
    onBookingDateAdd,
    onBookingDateRemove,
    onBookingDateEdit,
  } = props;

  const handleBookingDateAddClick = event => {
    event.preventDefault();
    onBookingDateAdd();
  };

  const showEstimatedBreakdown = hasBookingLineItems && !fetchLineItemsInProgress && !fetchLineItemsError;

  const bookingInfoMaybe = showEstimatedBreakdown ? (
    <div className={bookingTimeFormCss.priceBreakdownContainer}>
      <h3 className={bookingTimeFormCss.priceBreakdownTitle}>
        <FormattedMessage id="BookingTimeForm.priceBreakdownTitle" />
      </h3>
      <EstimatedBreakdownMaybe
        unitType={unitType}
        timeZone={timeZone}
        bookingDates={bookingDates}
        bookingLineItems={bookingLineItems}
        intl={intl}
      />
    </div>
  ) : null;

  const loadingSpinnerMaybe = fetchLineItemsInProgress ? (
    <IconSpinner className={bookingTimeFormCss.spinner} />
  ) : null;

  const bookingInfoErrorMaybe = fetchLineItemsError ? (
    <span className={bookingTimeFormCss.sideBarError}>
              <FormattedMessage id="BookingTimeForm.fetchLineItemsError" />
            </span>
  ) : null;

  const submitButtonClasses = classNames(
    submitButtonWrapperClassName || css.submitButtonWrapper
  );

  return (
    <>
      {bookingDates.map((item, index) => {
        return (
          <BookingTimeForm
            key={item.id}
            isVisibleRemoveButton={index > 0}
            bookingDateId={item.id}
            bookingDates={bookingDates}
            className={css.bookingForm}
            formId="BookingPanel"
            submitButtonWrapperClassName={css.submitButtonWrapper}
            unitType={unitType}
            // onSubmit={onSubmit}
            price={price}
            listingId={listingId}
            isOwnListing={isOwnListing}
            monthlyTimeSlots={monthlyTimeSlots}
            onFetchTimeSlots={onFetchTimeSlots}
            startDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
            endDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
            timeZone={timeZone}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
            onBookingDateRemove={onBookingDateRemove}
            onBookingDateEdit={onBookingDateEdit}
          />
        );
      })}

      <a
        href='#'
        onClick={handleBookingDateAddClick}
      >
        Add time slot
      </a>

      {bookingInfoMaybe}
      {loadingSpinnerMaybe}
      {bookingInfoErrorMaybe}

      <p className={bookingTimeFormCss.smallPrint}>
        <FormattedMessage
          id={
            isOwnListing
              ? 'BookingTimeForm.ownListing'
              : 'BookingTimeForm.youWontBeChargedInfo'
          }
        />
      </p>

      <div className={submitButtonClasses}>
        <PrimaryButton
          onClick={onSubmit}
        >
          <FormattedMessage id="BookingTimeForm.requestToBook" />
        </PrimaryButton>
      </div>
    </>
  );
};

BookingForm.propTypes = {
  hasBookingLineItems: bool,
  submitButtonWrapperClassName: string,
  unitType: propTypes.bookingUnitType,
  price: object,
  listingId: object,
  isOwnListing: bool,
  monthlyTimeSlots: object,
  timeZone: string,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
  lineItems: array,
  onSubmit: func.isRequired,
  onFetchTransactionLineItems: func.isRequired,
  onFetchTimeSlots: func.isRequired,
  onBookingDateAdd: func.isRequired,
  onBookingDateRemove: func.isRequired,
  onBookingDateEdit: func.isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};

export default BookingForm;
