import React from 'react';
import classNames from 'classnames';
import { func, string } from 'prop-types';

import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';

import css from './EditListingAvailabilityPanel.module.css';

const findEntry = (availabilityPlan, dayOfWeek) =>
  availabilityPlan.entries.find(d => d.dayOfWeek === dayOfWeek);

const getEntries = (availabilityPlan, dayOfWeek) =>
  availabilityPlan.entries.filter(d => d.dayOfWeek === dayOfWeek);

const Weekday = props => {
  const {
    availabilityPlan,
    dayOfWeek,
    onOpenEditModal,
  } = props;

  const handleOpenEditModalClick = () => {
    if (onOpenEditModal) {
      onOpenEditModal(true);
    }
  }

  const hasEntry = findEntry(availabilityPlan, dayOfWeek);

  return (
    <div
      className={classNames(css.weekDay, { [css.blockedWeekDay]: !hasEntry })}
      onClick={handleOpenEditModalClick}
      role="button"
    >
      <div className={css.dayOfWeek}>
        <FormattedMessage id={`EditListingAvailabilityPanel.dayOfWeek.${dayOfWeek}`} />
      </div>
      <div className={css.entries}>
        {availabilityPlan && hasEntry
          ? getEntries(availabilityPlan, dayOfWeek).map(e => {
            return (
              <span className={css.entry} key={`${e.dayOfWeek}${e.startTime}`}>{`${
                e.startTime
              } - ${e.endTime === '00:00' ? '24:00' : e.endTime}`}</span>
            );
          })
          : null}
      </div>
    </div>
  );
};

Weekday.propTypes = {
  availabilityPlan: propTypes.availabilityPlan.isRequired,
  dayOfWeek: string.isRequired,
  openEditModal: func,
};

export default Weekday;
